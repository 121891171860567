import React, { useEffect, useState } from "react";
import { Input } from "semantic-ui-react";

const AutoSaveInput = (props) => {
    const [timer, setTimer] = useState(null);
    
    useEffect(() => {
        return () => timer && clearTimeout(timer);
    }, []);

    const onBlurInner = () => {
        timer && clearTimeout(timer);
        props.onBlur && props.onBlur();
    };

    const onChangeInner = (e) => {
        timer && clearTimeout(timer);
        props.onChange && props.onChange(e);
        setTimer(setTimeout(onBlurInner, 2000));
    };

    var inputProps = {
        ...props,
        onChange: onChangeInner,
        onBlur: onBlurInner
    };

    if (props.textarea) {
        return <textarea {...inputProps}/>;
    }
    else {
        return <Input {...inputProps}/>;
    }
};

export default AutoSaveInput;