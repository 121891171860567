export const mapLinks = [
    {
        name: 'Генератор глобальных карт',
        url: 'https://azgaar.github.io/Fantasy-Map-Generator/'
    },
    {
        name: 'Генератор фэнтези городов',
        url: 'https://watabou.itch.io/medieval-fantasy-city-generator'
    },
    {
        name: 'Генератор деревень',
        url: 'https://watabou.itch.io/village-generator'
    },
    {
        name: 'Генератор пещер',
        url: 'https://watabou.itch.io/cave-generator'
    },
    {
        name: 'Генератор жилищ',
        url: 'https://watabou.itch.io/dwellings'
    },
    {
        name: 'Генератор таверн',
        url: 'https://tentaculus.ru/tavern/'
    }
];

export const personLinks = [
    {
        name: 'Генератор персонажей',
        url: 'http://dm-stuff.ru/generator-characters/'
    },
    {
        name: 'Генератор имен от dm-stuff',
        url: 'http://dm-stuff.ru/generator-names/'
    },
    {
        name: 'Генератор имен от tentaculus',
        url: 'https://tentaculus.ru/names/'
    }
];