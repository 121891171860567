import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Dropdown, Form, Label, List } from "semantic-ui-react";

import FormInput from '../../controls/input';
import { apiService } from '../../utils/apiService';

const GroupForm = () => {
    const { id } = useParams();
    
    const [newMemberEmail, setNewMemberEmail] = useState('');
    const [group, setGroup] = useState({});
    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        document.title = "Overdice";
    }, []);

    const getData = () => {
        setInProgress(true);
        apiService.get('/v1/groups/' + id)
            .then(result => {
                setInProgress(false);
                setGroup(result);
            })
            .catch(() => {
                setInProgress(false);
            });
    };
    useEffect(getData, [id]);

    const rename = (newName) => {
        setInProgress(true);
        apiService.put('/v1/groups/' + id, { name: newName })
            .then(() => getData())
            .catch(() => {
                setInProgress(false);
            });
    };

    const invite = (email) => {
        setInProgress(true);
        apiService.post('/v1/groups/' + id + '/members/add', { email })
            .then(() => getData())
            .catch(() => {
                setInProgress(false);
            });
    };

    const setStatus = (userId, status) => {
        setInProgress(true);
        apiService.put('/v1/groups/' + id + '/members/' + userId + '/status', { status })
            .then(() => getData())
            .catch(() => {
                setInProgress(false);
            });
    };

    const selectCharacter = (userId, characterId) => {
        setInProgress(true);
        apiService.put('/v1/groups/' + id + '/members/' + userId + '/character', { characterId })
            .then(() => getData())
            .catch(() => {
                setInProgress(false);
            });
    };

    const getStatus = (status) => {
        switch (status) {
            case 'Invited': return <Label horizontal>Приглашен</Label>
            case 'Accepted': return <Label color="blue" horizontal>Участвует</Label>
            case 'Declined': return <Label color="brown" horizontal>Отказ</Label>
            case 'Rejected': return <Label color="red" horizontal>Исключен</Label>
            case 'Leaved': return <Label color="black" horizontal>Вышел</Label>
            default: return null;
        }
    };

    const getCharacterInfo = (entry) => {
        if (entry)  {
            return entry.name + ' [' + entry.level + '] : ' + entry.race + ' ' + entry.class;
        } else {
            return 'Персонаж не выбран';
        }
    };

    const convertToCharacterOption = (entry) => {
        return {
            key: entry.id,
            text: getCharacterInfo(entry),
            value: entry.id
        }
    };

    const convertToStatusOption = (userId, status) => {
        return (
            <Dropdown.Item 
                text={status.name}
                key={'status-' + status.id}
                onClick={() => setStatus(userId, status.id)}
            />
        );
    };

    return (
        <Container>
            <Form>
                <Form.Group>
                    <FormInput transparent={!group.canEdit}
                        type="text"
                        value={group.name}
                        onChange={rename}
                        disabled={inProgress || !group.canEdit}
                    />
                </Form.Group>
            </Form>

            {group.canInvite && (
                <Form>
                    <Form.Group inline>
                        <Form.Input 
                            placeholder='Логин нового участника группы'
                            value={newMemberEmail}
                            onChange={(e, {value}) => setNewMemberEmail(value)}
                            disabled={inProgress}
                        />
                        <Form.Button
                            onClick={() => invite(newMemberEmail)}
                            disabled={inProgress}
                        >
                            Пригласить
                        </Form.Button>
                    </Form.Group>
                </Form>
            )}

            <List>
                {group.members && group.members.map((entry) => {
                    return (
                        <List.Item key={'group-' + group.id + '-member-' + entry.userId}>
                            <List.Icon name="user" size="large" verticalAlign="middle" />
                            <List.Content>
                                <List.Header>
                                    {entry.user && entry.user.email} &nbsp;
                                    {entry.status && getStatus(entry.status)} &nbsp;
                                    {entry.availableStatuses && entry.availableStatuses.length > 0 && (
                                        <Dropdown text="Изменить" disabled={inProgress}>
                                            <Dropdown.Menu>
                                                {entry.availableStatuses.map((value) => convertToStatusOption(entry.userId, value))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )}
                                </List.Header>
                                <List.Description>
                                    {entry.canSelectCharacter ? (
                                        <Dropdown
                                            placeholder="Игровой персонаж"
                                            search
                                            selection
                                            options={entry.availableCharacters && entry.availableCharacters.map(convertToCharacterOption)}
                                            value={entry.character && entry.character.id}
                                            onChange={(e, {value}) => selectCharacter(entry.userId, value)}
                                            disabled={inProgress}
                                        />
                                    ) : (
                                        <div>{getCharacterInfo(entry.character)}</div>
                                    )}
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    );
                })}
            </List>
        </Container>
    );
};

export default GroupForm;