import React from "react";
import { Menu, MenuItem } from "semantic-ui-react";
import DiceInput from "./diceInput";

const DiceWidgetMenu = ({
    right,
    bottom,
    dices,
    onDicesChanged,
    onClose
}) => {
    const changeDice = (index, dice) => {
        const newDices = []
            .concat(dices.slice(0, index))
            .concat(dice.count > 0 ? [dice] : [])
            .concat(dices.slice(index + 1));
        onDicesChanged(newDices);
    };

    const addDice = () => {
        const newDices = dices
            .concat([{
                dice: 20,
                count: 1
            }]);
        onDicesChanged(newDices);
    };

    return (
        <Menu
            vertical
            className="dices-widget-menu"
            style={{ right, bottom }}
        >
            <MenuItem 
                key='dices-menu-add'
                onClick={addDice}
            >
                Добавить
            </MenuItem>
            {dices.map((dice, index) => (
                <MenuItem
                    key={`dices-menu-${index}`}
                >
                    <DiceInput
                        dice={dice}
                        onDiceChanged={(newDice) => changeDice(index, newDice)}
                    />
                </MenuItem>
            ))}
            <MenuItem 
                key='dices-menu-close'
                onClick={onClose}
            >
                Закрыть
            </MenuItem>
        </Menu>
    );
};

export default DiceWidgetMenu;