import React from "react";
import { Button, Form, Input } from "semantic-ui-react";

const DiceInput = ({
    dice,
    onDiceChanged
}) => {
    return (
        <Form size="tiny">
            <Button 
                circular 
                icon="minus" 
                size="tiny"
                onClick={() => onDiceChanged({ ...dice, count: dice.count - 1 })} 
            />
            <Input
                type="number"
                label={`x ${dice.count}`}
                labelPosition="right"
                value={dice.dice}
                style={{
                    width: 65
                }}
                onChange={(e) => onDiceChanged({ ...dice, dice: parseInt(e.target.value) })}
            />
            <Button 
                circular 
                icon="plus" 
                size="tiny"
                style={{
                    marginLeft: 38
                }}
                onClick={() => onDiceChanged({ ...dice, count: dice.count + 1 })} 
            />
        </Form>
    );
};

export default DiceInput;