import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form, Grid } from "semantic-ui-react";
import { ROLE_MODELS_FORM_LINK, ROLE_MODELS_NEW_LINK } from "../../router/links";
import { apiService } from "../../utils/apiService";

const ModelsList = () => {
    const navigate = useNavigate();

    const [canEdit, setCanEdit] = useState(false);
    const [list, setList] = useState([]);
    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        document.title = "Overdice";
    }, []);

    const loadList = () => {
        setInProgress(true);
        apiService.get('/v1/models/list')
            .then(result => {
                setInProgress(false);
                setCanEdit(result && result.canEdit);
                setList((result && result.entries) || []);
            })
            .catch(() => setInProgress(false));
    };

    useEffect(loadList, []);

    const openNewForm = () => {
        navigate(ROLE_MODELS_NEW_LINK);
    };

    const openForm = (id) => {
        navigate(ROLE_MODELS_FORM_LINK.replace(':id', id));
    };

    const handleDelete = (id) => {
        setInProgress(true);
        apiService.delete('/v1/models/' + id)
            .then(loadList)
            .catch(() => setInProgress(false));
    };

    const renderItem = (item) => {
        return (
            <Grid.Row>
                <Grid.Column>
                    <Form inline>
                        <Form.Field inline>
                            <label>{item.name}</label>
                        </Form.Field>
                        {canEdit && (<Form.Button 
                            icon="pencil"
                            onClick={() => openForm(item.id)}
                        />)}
                        {canEdit && (<Form.Button 
                            icon="trash alternate"
                            onClick={() => handleDelete(item.id)}
                        />)}
                    </Form>
                </Grid.Column>
            </Grid.Row>
        );
    };

    return (
        <Container>   
            <Grid>
                {canEdit && (<Grid.Row>
                    <Grid.Column>
                        <Button 
                            onClick={openNewForm}
                            disabled={inProgress}
                        >
                            Создать новую
                        </Button>
                    </Grid.Column>
                </Grid.Row>)}
                {list.map((item) => renderItem(item))}
            </Grid>
        </Container>
    );
};

export default ModelsList;