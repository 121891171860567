import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Container, Form, Input } from 'semantic-ui-react';

import { apiService } from '../../utils/apiService';

const ProfilePage = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [data, setData] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const [notificationTypes, setNotificationTypes] = useState([]);

    useEffect(() => {
        document.title = "Overdice";

        setInProgress(true);
        apiService.get('/v1/profile')
            .then(result => {
                setInProgress(false);
                setData(result);
                
                if (result && result.notificationTypes) {
                    setNotificationTypes(result.notificationTypes);
                }
            })
            .catch(() => {
                setInProgress(false);
            });
    }, []);

    const onChangePassword = () => {
        const data = {
            oldPassword,
            newPassword
        };

        setInProgress(true);
        apiService.put('/v1/profile/password', data)
            .then(() => {
                setInProgress(false);
                setOldPassword('');
                setNewPassword('');
            })
            .catch(() => {
                setInProgress(false);
            });
    };

    const onToggleNotification = (value) => {
        let typesSet = new Set(notificationTypes)
        typesSet[typesSet.has(value) ? 'delete' : 'add'](value);
        let newNotificationTypes = Array.from(typesSet);

        setNotificationTypes(newNotificationTypes);

        setInProgress(true);
        apiService.put('/v1/profile/password', { notificationTypes: newNotificationTypes })
            .then(() => {
                setInProgress(false);
            })
            .catch(() => {
                setInProgress(false);
            });
    };

    return (
        <Container>
            <Form>
                <Form.Group widths={'equal'}>
                    <Form.Field>
                        <label>Текущий пароль</label>
                        <Input fluid 
                            type='password'
                            value={oldPassword}
                            onChange={(e, { value }) => setOldPassword(value)}
                            disabled={inProgress}
                        />
                    </Form.Field>
                    
                    <Form.Field>
                        <label>Новый пароль</label>
                        <Input fluid 
                            type='password'
                            value={newPassword}
                            onChange={(e, { value }) => setNewPassword(value)}
                            disabled={inProgress}
                        />
                    </Form.Field>

                    <Button primary onClick={onChangePassword}>Изменить пароль</Button>
                </Form.Group>

                <Form.Group>
                    <Form.Field>
                        {data && data.allNotificationTypes && data.allNotificationTypes.map((entry) => {
                            let isSelected = notificationTypes && notificationTypes.indexOf(entry.id) >= 0;
                            return (
                                <div>
                                    <Checkbox 
                                        key={'notification-' + entry.id}
                                        label={entry.name} 
                                        checked={isSelected} 
                                        onChange={() => onToggleNotification(entry.id)}
                                        disabled={inProgress}
                                    />
                                </div>
                            );
                        })}
                    </Form.Field>
                </Form.Group>
            </Form>
        </Container>
    );
}

export default ProfilePage;