import React from "react";
import NotesList from "../components/notesList";

const AdventureChapterNotesTab = ({
    adventureId,
    chapterId,
    disabled
}) => {
    return (
        <NotesList
            adventureId={adventureId}
            chapterId={chapterId}
            disabled={disabled}
        />
    );
};

export default AdventureChapterNotesTab;