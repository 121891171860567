import React, { useEffect, useState } from "react";
import { Button, Form, FormField, FormGroup, Modal } from "semantic-ui-react";
import ImageUploader from "../../../controls/imageUploader";

const CreateMaterialDialog = ({
    isOpen,
    disabled,
    title,
    errors,
    onCancel,
    onSave
}) => {
    const [name, setName] = useState('');
    const [file, setFile] = useState(null);

    useEffect(() => {
        setName('');
        setFile(null);
    }, [isOpen]);

    const saveData = () => {
        const form = new FormData();
        form.append('name', name);
        if (file) {
            form.append('file', file, file ? file.name : 'file');
        }
        onSave(form);
    };

    return (
        <Modal size="small" open={isOpen}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <Form>
                    <FormGroup>
                        <FormField 
                            width={16}
                            error={errors && errors['Name']}
                            title={errors && errors['Name']}
                        >
                            <label className="required-label">Название <span>*</span></label>
                            <input
                                value={name}
                                disabled={disabled}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </FormField>
                    </FormGroup>
                    <FormGroup>
                        <FormField 
                            width={16}
                            error={errors && errors['File']}
                            title={errors && errors['File']}
                        >
                            <label className="required-label">Изображение <span>*</span></label>
                            <ImageUploader 
                                disabled={disabled}
                                selectedFile={file}
                                onSelectFile={setFile}
                            />
                        </FormField>
                    </FormGroup>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    onClick={onCancel}
                >
                    Отмена
                </Button>
                <Button 
                    primary 
                    disabled={disabled}
                    onClick={saveData}
                >
                    Сохранить
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CreateMaterialDialog;