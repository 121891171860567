import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Grid, Icon, Tab } from 'semantic-ui-react';
import { ADVENTURE_CHAPTERS_HASH, ADVENTURE_INFO_HASH, ADVENTURE_MATERIALS_HASH, ADVENTURE_NOTES_HASH, ADVENTURE_PERSONS_HASH, ADVENTURE_PLACES_HASH, ADVENTURES_LIST_LINK } from '../../router/links';
import AdventureInfoTab from './tabs/adventureInfoTab';
import AdventureChaptersTab from './tabs/adventureChaptersTab';
import AdventurePersonsTab from './tabs/adventurePersonsTab';
import { loadAdventureData, updateAdventureField } from '../../api/adventures';
import AdventurePlacesTab from './tabs/adventurePlacesTab';
import { loadAdventurePlaces } from '../../api/adventurePlace';
import AutoSaveInput from './components/autoSaveInput';
import AdventureNotesTab from './tabs/adventureNotesTab';
import AdventureMaterialsTab from './tabs/adventureMaterialsTab';

const AdventurePage = () => {
    const { id } = useParams();
    const { hash } = useLocation();
    const navigate = useNavigate();
    
    const [activeTab, setActiveTab] = useState(0);
    const [adventure, setAdventure] = useState({});
    const [places, setPlaces] = useState([]);
    const [name, setName] = useState('');
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isPlacesLoading, setIsPlacesLoading] = useState(false);

    const reloadPlaces = () => {
        if (id) {
            setIsPlacesLoading(true);
            loadAdventurePlaces(id)
                .then(result => {
                    setIsPlacesLoading(false);
                    setPlaces(result);
                })
                .catch(() => setIsPlacesLoading(false));
        }
    };

    const getData = () => {
        setIsDataLoading(true);
        loadAdventureData(id)
            .then(result => {
                setIsDataLoading(false);

                setAdventure(result);
                setName(result.name);
                
                document.title = `Overdice - ${result.name || 'приключения'}`;

                reloadPlaces();
            })
            .catch(() => {
                setIsDataLoading(false);
            });
    }
    useEffect(getData, [id]);

    const saveField = (fieldName, value) => {
        setIsDataLoading(true);
        updateAdventureField(id, fieldName, value)
            .then(() => setIsDataLoading(false))
            .catch(() => setIsDataLoading(false));
    };

    const panes = [
        {
            tag: ADVENTURE_INFO_HASH,
            render: () => (
                <AdventureInfoTab 
                    key={'adventure-info-' + (adventure.id || 'none')}
                    disabled={isDataLoading}
                    adventure={adventure} 
                    onSaveField={saveField}
                />
            )
        },
        {
            tag: ADVENTURE_CHAPTERS_HASH,
            render: () => (
                <AdventureChaptersTab
                    key={'adventure-chapters-' + (adventure.id || 'none')}
                    disabled={isDataLoading}
                    adventureId={id}
                />
            )
        },
        {
            tag: ADVENTURE_PERSONS_HASH,
            render: () => (
                <AdventurePersonsTab
                    key={'adventure-persons-' + (adventure.id || 'none')}
                    disabled={isDataLoading}
                    adventureId={id}
                />
            )
        },
        {
            tag: ADVENTURE_PLACES_HASH,
            render: () => (
                <AdventurePlacesTab
                    key={'adventure-places-' + (adventure.id || 'none')}
                    disabled={isDataLoading || isPlacesLoading}
                    adventureId={id}
                    chapterId={null}
                    places={places}
                    onReload={reloadPlaces}
                />
            )
        },
        {
            tag: ADVENTURE_NOTES_HASH,
            render: () => (
                <AdventureNotesTab
                    key={'adventure-notes-' + (adventure.id || 'none')}
                    disabled={isDataLoading}
                    adventureId={id} 
                />
            )
        },
        {
            tag: ADVENTURE_MATERIALS_HASH,
            render: () => (
                <AdventureMaterialsTab
                    key={'adventure-materials-' + (adventure.id || 'none')}
                    disabled={isDataLoading}
                    adventureId={id}
                />
            )
        }
    ];

    const syncActiveTab = () => {
        if (hash) {
            panes.forEach((tab, index) => {
                if (tab.tag === hash) {
                    setActiveTab(index);
                }
            });
        } else {
            setActiveTab(0);
        }
    };
    useEffect(syncActiveTab, [hash]);

    const goBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
         } else {
            navigate(ADVENTURES_LIST_LINK, { replace: true });
         }
    };

    return (
        <Container>
            <Grid>
                <Grid.Row className='adventure-header'>
                    <div className='header-main'>
                        <div className='header-back'>
                            <Button circular basic icon='arrow left' onClick={goBack} />
                        </div>
                        <div className='header-title'>
                            <AutoSaveInput 
                                transparent 
                                size='big'
                                icon='globe'
                                iconPosition='left'
                                className='title-input'
                                disabled={isDataLoading}
                                value={name} 
                                onChange={(e) => setName(e.target.value)}
                                onBlur={() => saveField('name', name)}
                            />
                        </div>
                    </div>
                    <div className='header-links'>
                        <Link 
                            title="Описание"
                            to={ADVENTURE_INFO_HASH} 
                            className={'ui basic circular icon button' + (panes[activeTab].tag === ADVENTURE_INFO_HASH ? ' black' : '')}
                        >
                            <Icon name="info"/>
                        </Link>
                        <Link 
                            title="Главы"
                            to={ADVENTURE_CHAPTERS_HASH} 
                            className={'ui basic circular icon button' + (panes[activeTab].tag === ADVENTURE_CHAPTERS_HASH ? ' black' : '')}
                        >
                            <Icon name="list ol"/>
                        </Link>
                        <Link 
                            title="Персонажи"
                            to={ADVENTURE_PERSONS_HASH} 
                            className={'ui basic circular icon button' + (panes[activeTab].tag === ADVENTURE_PERSONS_HASH ? ' black' : '')}
                        >
                            <Icon name="group"/>
                        </Link>
                        <Link 
                            title="Локации"
                            to={ADVENTURE_PLACES_HASH} 
                            className={'ui basic circular icon button' + (panes[activeTab].tag === ADVENTURE_PLACES_HASH ? ' black' : '')}
                        >
                            <Icon name="map"/>
                        </Link>
                        <Link 
                            title="Заметки"
                            to={ADVENTURE_NOTES_HASH} 
                            className={'ui basic circular icon button' + (panes[activeTab].tag === ADVENTURE_NOTES_HASH ? ' black' : '')}
                        >
                            <Icon name="sticky note outline"/>
                        </Link>
                        <Link 
                            title="Материалы"
                            to={ADVENTURE_MATERIALS_HASH} 
                            className={'ui basic circular icon button' + (panes[activeTab].tag === ADVENTURE_MATERIALS_HASH ? ' black' : '')}
                        >
                            <Icon name="folder open outline"/>
                        </Link>
                    </div>
                </Grid.Row>
            </Grid>
            <Tab menu={{ as: () => <></> }} panes={panes} activeIndex={activeTab} />
        </Container>
    );
};

export default AdventurePage;