import React from "react";
import { BrowserRouter } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppAuthWrapper from "./appAuth";

import '../styles/app.css';
import '../styles/fantasyIcon.css';

const App = () => {
    return (
        <BrowserRouter>
            <AppAuthWrapper />
            <ToastContainer
                position="bottom-left"
                autoClose={30000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                theme="colored" 
            />
        </BrowserRouter>
    );
};

export default App;