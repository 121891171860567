import React, { useEffect, useState } from 'react';
import { Button, Form, Grid, Message, Segment } from 'semantic-ui-react';

import { LOGIN_LINK, REGISTER_LINK } from '../../router/links';
import { apiService } from '../../utils/apiService';
import LoginLogo from '../header/loginLogo';

const RestorePasswordPage = () => {
    let [email, setEmail] = useState('');
    let [inProgress, setInProgress] = useState(false);
    let [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        document.title = "Overdice";
    }, []);

    const onRestore = () => {
        setInProgress(true);
        apiService.post('/v1/users/password/restore', { email })
            .then(() => {
                setInProgress(false);
                setIsComplete(true);
            })
            .catch(() => {
                setInProgress(false);
            });
    };

    return (
        <Grid textAlign='center' verticalAlign='middle' style={{ height: '100vh' }}>
            <Grid.Column style={{ maxWidth: 400 }}>
                <LoginLogo />
                {isComplete ? (
                    <Message>
                        На вашу почту <b>{email}</b> отправлено письмо с ссылкой для установки нового пароля
                    </Message>
                ) : (
                    <Form size='large'>
                        <Segment stacked>
                            <Form.Input 
                                fluid
                                icon='user'
                                id='email'
                                iconPosition='left'
                                placeholder='Email'
                                autocomplete='on'
                                type='email'
                                disabled={inProgress}
                                onChange={(e, { value }) => setEmail(value)}
                            />
                            <Button fluid size='large' onClick={onRestore} disabled={inProgress}>
                                Восстановить пароль
                            </Button>
                        </Segment>
                    </Form>
                )}
                <Message>
                    <a href={LOGIN_LINK}>Войти в систему</a> / <a href={REGISTER_LINK}>Зарегистрироваться</a>
                </Message>
            </Grid.Column>
        </Grid>
    );
};

export default RestorePasswordPage;