import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown, Form, FormField, FormGroup, Grid, Header, Icon, Item, ItemContent, ItemDescription, ItemGroup, ItemHeader, ItemImage, List, ListItem, Pagination, Segment } from "semantic-ui-react";
import { ADVENTURE_CHAPTER_PAGE_LINK, ADVENTURE_PERSON_PAGE_LINK, ADVENTURE_PLACE_PAGE_LINK } from "../../../router/links";
import EditNoteDialog from "./editNoteDialog";
import { loadAdventureChaptersList } from "../../../api/adventureChapters";
import { loadAdventurePersonsList } from "../../../api/adventurePersons";
import { createAdventureNote, deleteAdventureNote, loadAdventureNotesList, updateAdventureNote } from "../../../api/adventureNotes";
import ConfirmationDialog from "../../../modals/confirmationDialog";
import { loadAdventurePlaces } from "../../../api/adventurePlace";

const NotesList = ({
    adventureId,
    chapterId,
    personId,
    placeId,
    disabled
}) => {
    const [editDialog, setEditDialog] = useState({ isOpen: false });
    const [editNoteErrors, setEditNoteErrors] = useState({});
    const [confirmationDialog, setConfirmationDialog] = useState({ isOpen: false });
    const [chapterIdFilter, setChapterIdFilter] = useState(null);
    const [personIdFilter, setPersonIdFilter] = useState(null);
    const [placeIdFilter, setPlaceIdFilter] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pagesCount, setPagesCount] = useState(1);
    const [notes, setNotes] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [persons, setPersons] = useState([]);
    const [places, setPlaces] = useState([]);

    useEffect(() => setChapterIdFilter(chapterId), [chapterId]);
    useEffect(() => setPersonIdFilter(personId), [personId]);
    useEffect(() => setPlaceIdFilter(placeId), [placeId]);
    
    const reloadList = () => {
        if (adventureId) {
            setIsLoading(true);
            loadAdventureNotesList(adventureId, chapterIdFilter, personIdFilter, placeIdFilter, pageNumber)
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setNotes(result.entries);
                        setPageNumber(result.pageNumber || 1);
                        setPagesCount(result.pagesCount || 1);
                    }
                })
                .catch(() => setIsLoading(false));
        }
    };

    const convertToOptions = (entry) => {
        return {
            key: entry.id,
            text: entry.name,
            value: entry.id
        };
    };

    const loadOptions = () => {
        if (adventureId) {
            loadAdventureChaptersList(adventureId).then(result => result && setChapters([{}].concat(result.entries.map(convertToOptions))));
            loadAdventurePersonsList(adventureId).then(result => result && setPersons([{}].concat(result.entries.map(convertToOptions))));
            loadAdventurePlaces(adventureId, null, null).then(result => setPlaces([{}].concat(result.map(convertToOptions))));
        }
    };

    useEffect(reloadList, [adventureId, chapterIdFilter, personIdFilter, placeIdFilter, pageNumber]);
    useEffect(loadOptions, [adventureId]);

    const cancelEdit = () => setEditDialog({ isOpen: false });

    const confirmEdit = (data) => {
        setIsLoading(true);
        setEditNoteErrors({});
        (data.id ? updateAdventureNote(data.id, data) : createAdventureNote(data))
            .then(() => {
                cancelEdit();
                reloadList();
            })
            .catch((result) => {
                setIsLoading(false);
                setEditNoteErrors(result && result.errors);
            });
    };

    const editNote = (entry) => {
        setEditDialog({ entry, isOpen: true });
        setEditNoteErrors({});
    }

    const createNote = () => editNote({
        adventure: { id: adventureId },
        chapter: chapterIdFilter ? { id: chapterIdFilter } : null,
        person: personIdFilter ? { id: personIdFilter } : null,
        place: placeIdFilter ? { id: placeIdFilter } : null,
    });

    const handleDelete = (id) => {
        setConfirmationDialog({
            message: `Вы действительно хотите удалить заметку?`,
            isOpen: true,
            id
        });
    };

    const cancelDelete = () => {
        setConfirmationDialog({
            isOpen: false
        });
    };

    const confirmDelete = () => {
        const id = confirmationDialog.id;
        cancelDelete();
        setIsLoading(true);
        deleteAdventureNote(id)
            .then(reloadList)
            .catch(() => setIsLoading(false));
    };

    const items = notes.map((entry, ind) => (
        <Item key={'note-' + ind}>
            <ItemImage style={{ width: 30 }}>
                <Icon name={entry.icon} size='big' />
            </ItemImage>
            <ItemContent>
                <ItemHeader>
                    {entry.name}
                </ItemHeader>
                <ItemDescription>
                    <List horizontal>
                        {entry.chapter ? (
                            <ListItem 
                                icon='list ol'
                                content={
                                <Link to={ADVENTURE_CHAPTER_PAGE_LINK.replace(':id', entry.chapter.id)}>
                                    {entry.chapter.name}
                                </Link>
                                }
                            />
                        ) : null}
                        {entry.person ? (
                            <ListItem 
                                icon='group'
                                content={
                                    <Link to={ADVENTURE_PERSON_PAGE_LINK.replace(':id', entry.person.id)}>
                                        {entry.person.name}
                                    </Link>
                                }
                            />
                        ) : null}
                        {entry.place ? (
                            <ListItem 
                                icon='map'
                                content={
                                    <Link to={ADVENTURE_PLACE_PAGE_LINK.replace(':id', entry.place.id)}>
                                        {entry.place.name}
                                    </Link>
                                }
                            />
                        ) : null}
                        <ListItem>
                            <Icon 
                                className="remove-button"
                                name="pencil alternate" 
                                title='Редактирование заметки'
                                onClick={() => editNote(entry)}
                            />
                        </ListItem>
                        <ListItem>
                            <Icon 
                                className="remove-button"
                                name="trash alternate" 
                                title='Удаление заметки'
                                onClick={() => handleDelete(entry.id)}
                            />
                        </ListItem>
                    </List>
                </ItemDescription>
            </ItemContent>
        </Item>
    ));

    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header size="medium" className="inline-title">
                            Заметки
                        </Header>
                        <Button
                            circular
                            icon='plus'
                            size="tiny"
                            className="inline-title"
                            disabled={isLoading || disabled}
                            title="Создание новой"
                            onClick={createNote}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ paddingTop: '0px' }}>
                    <Grid.Column>
                        <Segment>
                            <Grid>
                                <Grid.Row style={{ paddingBottom: '0px' }}>
                                    <Grid.Column>
                                        <Form size="small">
                                            <FormGroup inline>
                                                <FormField width={6} title="Фильтр по главам">
                                                    <Icon name='list ol' size="large" />
                                                    <Dropdown 
                                                        search
                                                        selection
                                                        clearable
                                                        value={chapterIdFilter}
                                                        onChange={(e, { value }) => setChapterIdFilter(value ? value : null)}
                                                        options={chapters}
                                                        disabled={disabled || isLoading || chapterId}
                                                    />
                                                </FormField>
                                                <FormField width={6} title="Фильтр по персонажам">
                                                    <Icon name='group' size="large" />
                                                    <Dropdown 
                                                        search
                                                        selection
                                                        clearable
                                                        value={personIdFilter}
                                                        onChange={(e, { value }) => setPersonIdFilter(value ? value : null)}
                                                        options={persons}
                                                        disabled={disabled || isLoading || personId}
                                                    />
                                                </FormField>
                                                <FormField width={6} title="Фильтр по локациям">
                                                    <Icon name='map' size="large" />
                                                    <Dropdown 
                                                        search
                                                        selection
                                                        clearable
                                                        value={placeIdFilter}
                                                        onChange={(e, { value }) => setPlaceIdFilter(value ? value : null)}
                                                        options={places}
                                                        disabled={disabled || isLoading || placeId}
                                                    />
                                                </FormField>
                                            </FormGroup>
                                        </Form>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <ItemGroup divided>{items}</ItemGroup>
                    </Grid.Column>
                </Grid.Row>
                {pagesCount > 1 ? (
                    <Grid.Row>
                        <Grid.Column>
                            <Pagination 
                                defaultActivePage={pageNumber} 
                                totalPages={pagesCount} 
                                secondary
                                onPageChange={(e, { activePage }) => setPageNumber(activePage)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                ) : null}
            </Grid>
            <EditNoteDialog
                entry={editDialog.entry}
                chapters={chapters}
                persons={persons}
                isOpen={editDialog.isOpen}
                disabled={isLoading || disabled}
                onCancel={cancelEdit}
                onSave={confirmEdit}
                errors={editNoteErrors}
            />
            <ConfirmationDialog
                isOpen={confirmationDialog.isOpen}
                title='Удаление заметки'
                message={confirmationDialog.message}
                onCancel={cancelDelete}
                onSave={confirmDelete}
            />
        </>
    );
};

export default NotesList;