export const LOGIN_LINK = '/login';
export const DASHBOARD_LINK = '/dashboard';
export const ADVENTURES_BASE_LINK = '/adventures/';
export const ADVENTURES_LIST_LINK = `${ADVENTURES_BASE_LINK}list`;
export const CHARACTER_FORM_LINK = '/character/form/:id';
export const CHARACTER_NEW_LINK = '/character/new';
export const GROUPS_BASE_LINK = '/groups/';
export const GROUPS_FORM_LINK = `${GROUPS_BASE_LINK}:id`;
export const GROUPS_LIST_LINK = `${GROUPS_BASE_LINK}list`;
export const ROLE_MODELS_BASE_LINK = '/models/';
export const ROLE_MODELS_FORM_LINK = `${ROLE_MODELS_BASE_LINK}:id`;
export const ROLE_MODELS_NEW_LINK = `${ROLE_MODELS_BASE_LINK}new`;
export const ROLE_MODELS_LIST_LINK = `${ROLE_MODELS_BASE_LINK}list`;
export const PROFILE_LINK = '/profile';
export const REGISTER_LINK = '/register';
export const PASSWORD_BASE_LINK = '/password/';
export const RESTORE_PASSWORD_LINK = `${PASSWORD_BASE_LINK}restore`;
export const SET_PASSWORD_LINK = `${PASSWORD_BASE_LINK}set/:id/:token`;

export const ADVENTURE_PAGE_LINK = `${ADVENTURES_BASE_LINK}:id`;
export const ADVENTURE_INFO_HASH = '#info';
export const ADVENTURE_CHAPTERS_HASH = '#chapters';
export const ADVENTURE_PERSONS_HASH = '#persons';
export const ADVENTURE_NOTES_HASH = '#notes';
export const ADVENTURE_PLACES_HASH = '#places';
export const ADVENTURE_MATERIALS_HASH = '#materials';

export const ADVENTURE_PERSON_PAGE_LINK = `${ADVENTURES_BASE_LINK}persons/:id`;
export const ADVENTURE_PERSON_INFO_HASH = '#info';
export const ADVENTURE_PERSON_NOTES_HASH = '#notes';

export const ADVENTURE_CHAPTER_PAGE_LINK = `${ADVENTURES_BASE_LINK}chapters/:id`;
export const ADVENTURE_CHAPTER_INFO_HASH = '#info';
export const ADVENTURE_CHAPTER_PLACES_HASH = '#places';
export const ADVENTURE_CHAPTER_NOTES_HASH = '#notes';

export const ADVENTURE_PLACE_PAGE_LINK = `${ADVENTURES_BASE_LINK}places/:id`;
export const ADVENTURE_PLACE_MAP_HASH = '#map';
export const ADVENTURE_PLACE_DESCRIPTION_HASH = '#description';

export const PUBLIC_PAGE_PREFIX = '/public/data/';
export const PUBLIC_ADVENTURE_MATERIAL_PAGE = `${PUBLIC_PAGE_PREFIX}adventure/:adventureId/:materialType/:materialId`;
