import './mapContainer.css';
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import CustomContextMenu from './customContextMenu';
import { Dimmer, Icon, Loader } from 'semantic-ui-react';

const MapContainer = ({
    id,
    mapUrl,
    fullscreen,
    points,
    contextMenuItems,
    disabled
}) => {
    const mapTileId = "map-tile";
    const viewportXKey = 'viewport-x';
    const viewportYKey = 'viewport-y';
    const viewportScaleKey = 'viewport-scale';

    const containerRef = useRef();
    const imgRef = useRef();

    const [viewport, setViewport] = useState({
        x: 0,
        y: 0,
        scale: 1
    });

    const [isLoading, setIsLoading] = useState(true);
    const [contextMenu, setContentMenu] = useState({ isOpen: false });
    const [pointContextMenu, setPointContextMenu] = useState({ isOpen: false });
    const [movingPoint, setMovingPoint] = useState(null);
    const [containerHeight, setContainerHeight] = useState(1);
    const [pointList, setPointsList] = useState([]);

    useEffect(() => setPointsList(points), [points]);

    const closeContextMenus = () => {
        setContentMenu({ isOpen: false });
        setPointContextMenu({ isOpen: false });
    }

    useLayoutEffect(() => {
        const syncWindowSize = () => {
            containerRef.current && setContainerHeight(containerRef.current.offsetParent.offsetHeight - containerRef.current.offsetTop - 10);
        };

        window.addEventListener('resize', syncWindowSize);
        syncWindowSize();

        return () => {
            window.removeEventListener('resize', syncWindowSize);
        };
    }, []);

    const loadViewport = () => {
        if (id) {
            const x = parseFloat(localStorage.getItem(`${viewportXKey}-${id}`));
            const y = parseFloat(localStorage.getItem(`${viewportYKey}-${id}`));
            const scale = parseFloat(localStorage.getItem(`${viewportScaleKey}-${id}`));
            if (!isNaN(x) && !isNaN(y) && !isNaN(scale)) {
                return { x, y, scale };
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    const saveViewport = (viewport) => {
        if (id) {
            localStorage.setItem(`${viewportXKey}-${id}`, '' + viewport.x);
            localStorage.setItem(`${viewportYKey}-${id}`, '' + viewport.y);
            localStorage.setItem(`${viewportScaleKey}-${id}`, '' + viewport.scale);
        }
    };

    const initializeMap = () => {
        const lastViewport = loadViewport();
        if (lastViewport) {
            setViewport(lastViewport);
        } else {
            const scale = Math.min(
                containerRef.current.clientWidth / imgRef.current.naturalWidth,
                containerRef.current.clientHeight / imgRef.current.naturalHeight
            );
            const newViewport = {
                scale,
                x: -Math.floor((containerRef.current.clientWidth - imgRef.current.naturalWidth * scale) / 2),
                y: -Math.floor((containerRef.current.clientHeight - imgRef.current.naturalHeight * scale) / 2)
            };
            saveViewport(newViewport);
            setViewport(newViewport);
        }
    };

    useEffect(() => {
        let hasListener = false;

        const onImgLoaded = () => {
            imgRef.current.removeEventListener('load', onImgLoaded);

            initializeMap();
            setIsLoading(false);
        };

        if (imgRef && imgRef.current) {
            if (imgRef.current.complete && imgRef.current.naturalWidth > 0) {
                initializeMap();
                setIsLoading(false);
            } else {
                imgRef.current.addEventListener('load', onImgLoaded);
                hasListener = true;
            }
        }

        return () => {
            hasListener && imgRef && imgRef.current && imgRef.current.removeEventListener('load', onImgLoaded);
        };
    }, [imgRef]);

    const scale = (v) => Math.floor(v * viewport.scale);
    const unScale = (v) => Math.floor(v / viewport.scale);

    const getPointPosition = (point) => ({
        x: scale(point.x) - viewport.x,
        y: scale(point.y) - viewport.y
    });

    const getRealPosition = (point) => ({
        x: unScale(point.x + viewport.x),
        y: unScale(point.y + viewport.y)
    });

    const fixScale = (newScale) => Math.max(Math.min(newScale, 100), 0.1);

    const fixX = (newX, actualWidth) => 
        Math.max(
            Math.min(
                newX, 
                Math.max(actualWidth - containerRef.current.clientWidth, 0)
            ), 
            Math.min(actualWidth - containerRef.current.clientWidth, 0)
        );

    const fixY = (newY, actualHeight) => 
        Math.max(
            Math.min(
                newY, 
                Math.max(actualHeight - containerRef.current.clientHeight, 0)
            ), 
            Math.min(actualHeight - containerRef.current.clientHeight, 0)
        );

    const onWheelImage = (e) => {
        const { deltaY, offsetX, offsetY } = e.nativeEvent;
        const { id, offsetWidth, offsetHeight } = e.target;
        if (id === mapTileId) {
            const newScale = fixScale(viewport.scale * (1 - deltaY / 1000));

            const scaleFactor = newScale / viewport.scale;
            const newX = fixX(viewport.x + Math.floor(offsetX * (scaleFactor - 1)), Math.floor(offsetWidth * scaleFactor));
            const newY = fixY(viewport.y + Math.floor(offsetY * (scaleFactor - 1)), Math.floor(offsetHeight * scaleFactor));

            const newViewport = {
                x: newX,
                y: newY,
                scale: newScale
            };
            saveViewport(newViewport);
            setViewport(newViewport);
        }
    };

    const setPointPosition = (index, x, y) => {
        const oldPoint = pointList[index];
        const newPointsList = []
            .concat(pointList.slice(0, index))
            .concat([{ ...oldPoint, x, y }])
            .concat(pointList.slice(index + 1));

        setPointsList(newPointsList);
    }

    const movePoint = (movingPoint, diffX, diffY) => {
        const newViewPoint = { x: movingPoint.viewPoint.x + diffX, y: movingPoint.viewPoint.y + diffY };
        const newRealPoint = getRealPosition(newViewPoint);
        const minDiff = 5;

        setMovingPoint({
            ...movingPoint,
            viewPoint: newViewPoint,
            hasMoved: Math.abs(movingPoint.originalViewPoint.x - newViewPoint.x) > minDiff 
                || Math.abs(movingPoint.originalViewPoint.y - newViewPoint.y) > minDiff
        });

        setPointPosition(movingPoint.index, newRealPoint.x, newRealPoint.y);
    };

    const resetPointMoving = (movingPoint) => {
        const newRealPoint = getRealPosition(movingPoint.originalViewPoint);
        setPointPosition(movingPoint.index, newRealPoint.x, newRealPoint.y);
    }

    const onMouseMoveImage = (e) => {
        const { movementX, movementY, buttons } = e;
        const { id, offsetWidth, offsetHeight } = e.target;

        if (movingPoint) {
            movePoint(movingPoint, movementX, movementY);
        } else if (id === mapTileId && buttons === 1) {
            const newX = fixX(viewport.x - movementX, offsetWidth);
            const newY = fixY(viewport.y - movementY, offsetHeight);

            const newViewport = {
                ...viewport,
                x: newX,
                y: newY
            };
            saveViewport(newViewport);
            setViewport(newViewport);
        }         
    };

    const onPointMouseMove = (e, index) => {
        const { movementX, movementY } = e;

        if (movingPoint && movingPoint.index === index) {
            movePoint(movingPoint, movementX, movementY);
        }
    };

    const onMovingPointEnd = () => {
        if (movingPoint) {
            const point = pointList[movingPoint.index];

            if (movingPoint.hasMoved) {
                point.onMove(point.x, point.y);
            } else {
                resetPointMoving(movingPoint);
            }

            setMovingPoint(null);
        }
    };

    const onMouseOutImage = () => {
        onMovingPointEnd();
    };

    const onImageContextMenu = (e) => {
        e.preventDefault();

        if (contextMenuItems) {
            const { offsetX, offsetY } = e.nativeEvent;
            const viewPoint = { x: offsetX - viewport.x, y: offsetY - viewport.y };
            const realPoint = getRealPosition(viewPoint);

            setContentMenu({
                isOpen: true,
                x: offsetX - viewport.x,
                y: offsetY - viewport.y,
                mapX: realPoint.x,
                mapY: realPoint.y
            });
        }
    };

    const onPointContextMenu = (e, point) => {
        e.preventDefault();

        if (point.contextItems) {
            const position = getPointPosition(point);
            setPointContextMenu({
                isOpen: true,
                x: position.x + 7,
                y: position.y - 7,
                mapX: point.x,
                mapY: point.y,
                items: point.contextItems
            });
        }
    };

    const onPointMouseDown = (e, point, index) => {
        e.preventDefault();

        if (point.onMove) {
            const viewPoint = getPointPosition(point);
            setMovingPoint({ 
                index, 
                viewPoint,
                originalViewPoint: viewPoint,
                hasMoved: false 
            });
        }
    };

    const onPointMouseUp = (e, point) => {
        e.preventDefault();

        if (movingPoint) {
            if (movingPoint.hasMoved) {
                onMovingPointEnd();
            } else {
                if (e.detail === 2) {
                    point.onDoubleClick && point.onDoubleClick();
                }

                resetPointMoving(movingPoint);
                setMovingPoint(null);
            }
        }
    };

    return (
        <div 
            ref={containerRef}
            className='map-container'
            onWheel={onWheelImage}
            onMouseMove={onMouseMoveImage}
            onMouseOut={onMouseOutImage}
            style={{
                height: containerHeight,
                marginLeft: fullscreen && '10px',
                marginRight: fullscreen && '10px'
            }}
        >
            {(isLoading || disabled) && (
                <Dimmer active inverted>
                    <Loader inverted />
                </Dimmer>
            )}

            <img 
                ref={imgRef}
                id={mapTileId}
                src={mapUrl}
                alt=""
                draggable={false}
                onContextMenu={onImageContextMenu}
                onClick={closeContextMenus}
                style={{
                    left: -viewport.x,
                    top: -viewport.y,
                    width: imgRef.current && imgRef.current.naturalWidth > 0 ? scale(imgRef.current.naturalWidth) : 'auto'
                }}
            />

            {pointList && pointList.map((point, index) => {
                const position = getPointPosition(point);
                return (
                    <Icon
                        circular
                        key={"map-point" + index}
                        name={point.icon}
                        title={point.title}
                        className='map-point'
                        onMouseMove={(e) => onPointMouseMove(e, index)}
                        onMouseDown={(e) => onPointMouseDown(e, point, index)}
                        onMouseUp={(e) => onPointMouseUp(e, point)}
                        onContextMenu={(e) => onPointContextMenu(e, point)}
                        style={{
                            left: position.x - 28,
                            top: position.y - 28,
                        }}
                    />
                );
            })}

            <CustomContextMenu 
                key="map-image-context-menu"
                items={contextMenuItems}
                isOpen={contextMenu.isOpen}
                x={contextMenu.x}
                y={contextMenu.y}
                mapX={contextMenu.mapX}
                mapY={contextMenu.mapY}
                onItemClick={closeContextMenus}
            />

            <CustomContextMenu
                key="map-point-context-menu"
                items={pointContextMenu.items}
                isOpen={pointContextMenu.isOpen}
                x={pointContextMenu.x}
                y={pointContextMenu.y}
                mapX={pointContextMenu.mapX}
                mapY={pointContextMenu.mapY}
                onItemClick={closeContextMenus}
            />
        </div>
    );
};

export default MapContainer;