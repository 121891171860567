import React, { useEffect, useState } from "react";
import { Button, Dropdown, DropdownItem, DropdownMenu, Grid, Header, Icon, Item, ItemContent, ItemDescription, ItemGroup, ItemHeader, ItemImage, List, ListItem } from "semantic-ui-react";
import ConfirmationDialog from "../../../modals/confirmationDialog";
import CreatePlaceDialog from "../components/createPlaceDialog";
import { createAdventurePlace, deleteAdventurePlace } from "../../../api/adventurePlace";
import { Link } from "react-router-dom";
import { ADVENTURE_CHAPTER_PAGE_LINK, ADVENTURE_PLACE_PAGE_LINK } from "../../../router/links";
import { mapLinks } from "../../../api/externalLinks";
import { loadAdventureChaptersList } from "../../../api/adventureChapters";

const AdventurePlacesTab = ({
    adventureId,
    chapterId,
    places,
    disabled,
    onReload
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState({ isOpen: false });
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const [newEntryErrors, setNewEntryErrors] = useState({});
    const [chapters, setChapters] = useState([]);

    const convertToOptions = (entry) => {
        return {
            key: entry.id,
            text: entry.name,
            value: entry.id
        };
    };
    useEffect(
        () => {
            setIsLoading(true);
            loadAdventureChaptersList(adventureId)
                .then(result => {
                    setIsLoading(false);
                    result && setChapters(result.entries.map(convertToOptions));
                })
                .catch(() => setIsLoading(false));
        }, 
        []
    );

    const onAddNewPlace = (form) => {
        if (adventureId) {
            setIsLoading(true);
            setNewEntryErrors({});
            createAdventurePlace(adventureId, form)
                .then(() => {
                    setIsNewDialogOpen(false);
                    onReload();
                    setIsLoading(false);
                })
                .catch((result) => {
                    setIsLoading(false);
                    setNewEntryErrors(result && result.errors);
                });
        }
    };

    const handleDelete = (id, name) => {
        setConfirmationDialog({
            message: `Вы действительно хотите удалить локацию '${name}'?`,
            isOpen: true,
            id
        });
    };

    const cancelDelete = () => {
        setConfirmationDialog({
            isOpen: false
        });
    };

    const confirmDelete = () => {
        const id = confirmationDialog.id;
        cancelDelete();
        setIsLoading(true);
        deleteAdventurePlace(id)
            .then(() => {
                onReload();
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    const items = places.map((entry, ind) => (
        <Item 
            key={'place-' + ind}
            style={{
                paddingLeft: entry.depth > 0 ? (entry.depth * 40) : 0
            }}
        >
            <ItemImage style={{ width: 25, cursor: 'pointer' }}>
                <Icon name="map marker" size='large' />
            </ItemImage>
            <ItemContent>
                <ItemHeader>
                    <Link to={ADVENTURE_PLACE_PAGE_LINK.replace(':id', entry.id)}>
                        {entry.name}
                    </Link>
                    <Icon 
                        className="remove-button"
                        name="trash alternate" 
                        title='Удаление локации'
                        onClick={() => handleDelete(entry.id, entry.name)}
                    />
                </ItemHeader>
                {chapterId || entry.depth > 0 ? null : entry.chapterId && (
                    <ItemDescription>
                        <List horizontal>
                            <ListItem 
                                icon='list ol'
                                content={
                                    <Link to={ADVENTURE_CHAPTER_PAGE_LINK.replace(':id', entry.chapterId)}>
                                        {entry.chapterName}
                                    </Link>
                                }
                            />
                        </List>
                    </ItemDescription>
                )}
            </ItemContent>
        </Item>
    ));

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size="medium" className="inline-title">
                        Локации
                    </Header>
                    <Button
                        circular
                        icon='plus'
                        size="tiny"
                        className="inline-title"
                        disabled={isLoading || disabled}
                        title="Создание новой"
                        style={{ marginRight: 6 }}
                        onClick={() => {
                            setIsNewDialogOpen(true);                
                            setNewEntryErrors({});
                        }}
                    />
                    <Dropdown 
                        icon="external share" 
                        className="ui basic circular icon button"
                        title="Полезные ссылки"
                        simple
                    >
                        <DropdownMenu>
                            {mapLinks.map((link, index) => (
                                <DropdownItem
                                    key={`external-link-${index}`}
                                >
                                    <Link to={link.url} target="__blank">
                                        {link.name}
                                    </Link>
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <ItemGroup divided>{items}</ItemGroup>
                </Grid.Column>
            </Grid.Row>
            <CreatePlaceDialog
                isOpen={isNewDialogOpen}
                title='Создание новой локации'
                chapters={chapters}
                fixedChapterId={chapterId}
                onCancel={() => setIsNewDialogOpen(false)}
                onSave={onAddNewPlace}
                errors={newEntryErrors}
            />
            <ConfirmationDialog
                isOpen={confirmationDialog.isOpen}
                title='Удаление локации'
                message={confirmationDialog.message}
                onCancel={cancelDelete}
                onSave={confirmDelete}
            />
        </Grid>
    );
};

export default AdventurePlacesTab;