import React, { useEffect, useState } from "react";
import { Button, Grid, Header, Icon, Item, ItemContent, ItemGroup, ItemHeader, ItemImage, Pagination } from "semantic-ui-react";
import ConfirmationDialog from "../../../modals/confirmationDialog";
import { createAdventureMaterial, deleteAdventureMaterial, loadAdventureMaterials, updateAdventureMaterial } from "../../../api/adventureMaterials";
import { Link } from "react-router-dom";
import { PUBLIC_ADVENTURE_MATERIAL_PAGE } from "../../../router/links";
import CreateMaterialDialog from "../components/createMaterialDialog";
import AutoSaveInput from "../components/autoSaveInput";

const AdventureMaterialsTab = ({
    adventureId,
    disabled
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState({ isOpen: false });
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const [newEntryErrors, setNewEntryErrors] = useState({});
    const [materials, setMaterials ] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pagesCount, setPagesCount] = useState(1);
        
    const reloadMaterials = () => {
        if (adventureId) {
            setIsLoading(true);
            loadAdventureMaterials(adventureId, pageNumber)
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setMaterials(result.entries);
                        setPageNumber(result.pageNumber || 1);
                        setPagesCount(result.pagesCount || 1);
                    }
                })
                .catch(() => setIsLoading(false));
        }
    };
    
    useEffect(reloadMaterials, [adventureId, pageNumber]);

    const onAddNewPlace = (form) => {
        if (adventureId) {
            setIsLoading(true);
            setNewEntryErrors({});
            createAdventureMaterial(adventureId, form)
                .then(() => {
                    setIsNewDialogOpen(false);
                    reloadMaterials();
                    setIsLoading(false);
                })
                .catch((result) => {
                    setIsLoading(false);
                    setNewEntryErrors(result && result.errors);
                });
        }
    };

    const handleDelete = (id, name) => {
        setConfirmationDialog({
            message: `Вы действительно хотите удалить материал '${name}'?`,
            isOpen: true,
            id
        });
    };

    const cancelDelete = () => {
        setConfirmationDialog({
            isOpen: false
        });
    };

    const confirmDelete = () => {
        const id = confirmationDialog.id;
        cancelDelete();
        setIsLoading(true);
        deleteAdventureMaterial(id)
            .then(() => {
                reloadMaterials();
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    const onChangeName = (index, newName) => {
        const oldEntry = materials[index];
        const newMaterialsList = []
            .concat(materials.slice(0, index))
            .concat([{ ...oldEntry, name: newName }])
            .concat(materials.slice(index + 1));

        setMaterials(newMaterialsList);
    };

    const onRename = (entry) => {
        setIsLoading(true);
        updateAdventureMaterial(entry.id, { name: entry.name })
            .then(() => {
                reloadMaterials();
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    const items = materials.map((entry, ind) => (
        <Item 
            key={'material-' + ind}
        >
            <ItemImage style={{ width: 25, cursor: 'pointer' }}>
                <Icon name="file image outline" size='large' />
            </ItemImage>
            <ItemContent>
                <ItemHeader>
                    <AutoSaveInput 
                        transparent 
                        disabled={isLoading}
                        value={entry.name}
                        onChange={(e) => onChangeName(ind, e.target.value)}
                        onBlur={() => onRename(entry)}
                        style={{
                            width: `${entry.name.length + 1}ch`
                        }}
                    />
                    <Link 
                        title="Открыть как материал"
                        to={PUBLIC_ADVENTURE_MATERIAL_PAGE.replace(':adventureId', adventureId).replace(':materialType', entry.fileSource).replace(':materialId', entry.id)} 
                        target="__blank"
                        className={'icon'}
                    >
                        <Icon name="eye"/>
                    </Link>
                    <Icon 
                        className="remove-button"
                        name="trash alternate" 
                        title='Удаление материала'
                        onClick={() => handleDelete(entry.id, entry.name)}
                    />
                </ItemHeader>
            </ItemContent>
        </Item>
    ));

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size="medium" className="inline-title">
                        Материалы
                    </Header>
                    <Button
                        circular
                        icon='plus'
                        size="tiny"
                        className="inline-title"
                        disabled={isLoading || disabled}
                        title="Добавление нового материала"
                        onClick={() => {
                            setIsNewDialogOpen(true);
                            setNewEntryErrors({});
                        }}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <ItemGroup divided>{items}</ItemGroup>
                </Grid.Column>
            </Grid.Row>
            {pagesCount > 1 ? (
                <Grid.Row>
                    <Grid.Column>
                        <Pagination 
                            defaultActivePage={pageNumber} 
                            totalPages={pagesCount} 
                            secondary
                            onPageChange={(e, { activePage }) => setPageNumber(activePage)}
                        />
                    </Grid.Column>
                </Grid.Row>
            ) : null}
            <CreateMaterialDialog
                isOpen={isNewDialogOpen}
                disabled={isLoading || disabled}
                title='Добавление нового материала'
                onCancel={() => setIsNewDialogOpen(false)}
                onSave={onAddNewPlace}
                errors={newEntryErrors}
            />
            <ConfirmationDialog
                isOpen={confirmationDialog.isOpen}
                title='Удаление материала'
                message={confirmationDialog.message}
                onCancel={cancelDelete}
                onSave={confirmDelete}
            />
        </Grid>
    );
};

export default AdventureMaterialsTab;