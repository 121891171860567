import React, { useEffect, useRef, useState } from "react";
import { 
    BlockTypeSelect,
    BoldItalicUnderlineToggles, 
    CodeToggle, 
    ListsToggle, 
    MDXEditor, 
    UndoRedo, 
    headingsPlugin, 
    listsPlugin, 
    quotePlugin, 
    toolbarPlugin 
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';

const MarkdownEditor = ({
    disabled,
    onBlur,
    onChange,
    value,
}) => {
    const editorRef =  useRef(null);
    const [timer, setTimer] = useState(null);

    useEffect(
        () => editorRef.current?.setMarkdown(value || ''), 
        [value]
    );

    useEffect(() => {
        return () => timer && clearTimeout(timer);
    }, []);

    const onBlurInner = () => {
        timer && clearTimeout(timer);
        onBlur && onBlur();
    };

    const onChangeInner = (value) => {
        timer && clearTimeout(timer);
        onChange && onChange(value);
        setTimer(setTimeout(onBlurInner, 2000));
    };

    return (
        <MDXEditor 
            ref={editorRef}
            markdown=""
            style={{maxHeight: 100}}
            disabled={disabled}
            plugins={[
                toolbarPlugin({
                    toolbarContents: () => (
                    <>
                        {' '}
                        <UndoRedo />
                        <BoldItalicUnderlineToggles />
                        <BlockTypeSelect />
                        <ListsToggle />
                        <CodeToggle />
                    </>
                    )
                }),
                headingsPlugin({ allowedHeadingLevels: [1, 2, 3] }),
                listsPlugin(),
                quotePlugin(),
            ]}
            onChange={onChangeInner}
            onBlur={onBlurInner}
        />
    );
};

export default MarkdownEditor;