import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Container, Form, Grid, Icon, Input, Menu, Modal, Segment, Tab } from "semantic-ui-react";
import { apiService } from "../../utils/apiService";
import { ROLE_MODELS_LIST_LINK } from "../../router/links";
import FieldGroupForm from "./components/fieldGroupForm";

const ModelsForm = () => {
    const navigate = useNavigate();

    const { id } = useParams();

    const [form, setForm] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const [saveModal, setSaveModal] = useState({
        open: false,
        comment: ''
    });

    useEffect(() => {
        document.title = "Overdice";
    }, []);

    const loadData = (id) => {
        setInProgress(true);
        apiService.get('/v1/models/' + id)
            .then(result => {
                setInProgress(false);
                setForm(result);
            })
            .catch(() => setInProgress(false));
    };

    useEffect(() => {
        id && loadData(id);
    }, [id]);

    const handleFieldChange = (name, value) => {
        const newForm = {
            ...form,
            [name]: value
        };
        setForm(newForm);
    };

    const goToList = () => {
        navigate(ROLE_MODELS_LIST_LINK);
    };

    const handleSave = () => {
        setSaveModal({
            ...saveModal,
            open: true
        });
    };

    const onConfirmSave = () => {
        setInProgress(true);

        const saveForm = {
            ...form,
            updatesComment: saveModal.comment
        }

        if (id) {
            apiService.put('/v1/models/' + id, saveForm)
                .then(goToList)
                .catch(() => setInProgress(false));
        } else {
            apiService.post('/v1/models/create', saveForm)
                .then(goToList)
                .catch(() => setInProgress(false));
        }
    };

    const onCancelSave = () => {
        setSaveModal({
            ...saveModal,
            open: false
        });
    };

    const handleSaveCommentChanged = (value) => {
        setSaveModal({
            ...saveModal,
            comment: value
        });
    };

    const getFieldGroupPanes = () => {
        let panes = [];
        form && form.fieldGroups && form.fieldGroups.forEach((group, index) => {
            panes.push({
                menuItem: group.name,
                render: () => 
                    <FieldGroupForm 
                        form={group} 
                        onChange={(newGroup) => handleFieldGroupChange(newGroup, index)} 
                        onDelete={() => handleFieldGroupDelete(index)}
                    />
            });
        });
        panes.push({
            menuItem: (
                <Menu.Item position="left">
                    <Icon name="add circle" color="green" />
                    <label>Добавить группу</label>
                </Menu.Item>
            ),
            render: () => <></>
        });
        return panes;
    };

    const handleGroupTabChange = (index) => {
        if (form && form.fieldGroups && form.fieldGroups.length === index) {
            const newForm = {
                ...form,
                fieldGroups: [...form.fieldGroups].concat([{
                    name: 'Новая группа'
                }])
            };
            setForm(newForm);
        }
    };

    const handleFieldGroupChange = (newGroup, index) => {
        if (form && form.fieldGroups) {
            const newForm = {
                ...form,
                fieldGroups: form.fieldGroups.slice(0, index)
                                .concat([newGroup])
                                .concat(form.fieldGroups.slice(index + 1))
            };
            setForm(newForm);
        }
    };

    const handleFieldGroupDelete = (index) => {
        if (form && form.fieldGroups) {
            const newForm = {
                ...form,
                fieldGroups: form.fieldGroups.slice(0, index)
                                .concat(form.fieldGroups.slice(index + 1))
            };
            setForm(newForm);
        }
    };

    return (
        <>
            <Container>   
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <div className="card-header-panel">
                                    <div className="card-header-panel-title">
                                        <Input type="text"
                                            value={form.name || ''}
                                            onChange={(e, { value }) => handleFieldChange('name', value)}
                                            disabled={inProgress}
                                        />
                                    </div>
                                    <div class="card-header-panel-actions">
                                        <Button
                                            primary
                                            onClick={handleSave}
                                            disabled={inProgress}
                                        >
                                            Сохранить
                                        </Button>
                                        <Button
                                            onClick={goToList}
                                            disabled={inProgress}
                                        >
                                            Отменить
                                        </Button>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Группы полей</label>
                                    <Segment>
                                        <Tab 
                                            menu={{ vertical: true }}
                                            panes={getFieldGroupPanes()}
                                            onTabChange={(e, { activeIndex }) => handleGroupTabChange(activeIndex)}
                                        />
                                    </Segment>
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </Container>
            <Modal size="tiny" open={saveModal.open}>
                <Modal.Header>Новая версия модели</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Комментарий к новой версии</label>
                            <textarea rows={3}
                                value={saveModal.comment}
                                onChange={e => handleSaveCommentChanged(e.target.value)}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onCancelSave}>
                        Отмена
                    </Button>
                    <Button primary onClick={onConfirmSave}>
                        Сохранить
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default ModelsForm;