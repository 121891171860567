import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MapContainer from "../containers/adventures/components/mapContainer";
import { findAdventureMaterial } from "../api/adventureMaterials";
import AppHeader from "../containers/header";

const AdventureMaterialPage = () => {
    const { adventureId, materialType, materialId } = useParams();

    const [fileId, setFileId] = useState(null);
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        document.title = "Overdice";
    }, []);

    useEffect(() => {
        findAdventureMaterial(adventureId, materialType, materialId)
            .then(result => {
                setFileId(result.fileId);
                document.title = `Overdice - ${result.name || 'материалы'}`;
            })
            .catch(() => setHasErrors(true));
    }, [adventureId, materialType, materialId]);

    return (
        <>
            <AppHeader isEmpty />
            {!hasErrors && (
                <MapContainer
                    fullscreen
                    mapUrl={fileId && `/api/v1/files/${materialType}/${fileId}`}
                    disabled={fileId === null}
                />
            )}
        </>
    );
};

export default AdventureMaterialPage;