import React from "react";
import NotesList from "../components/notesList";

const AdventureNotesTab = ({
    adventureId,
    disabled
}) => {
    return (
        <NotesList
            adventureId={adventureId}
            disabled={disabled}
        />
    );
};

export default AdventureNotesTab;