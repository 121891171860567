import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createAdventurePlace, updateAdventurePlace, replaceAdventurePlaceFile, deleteAdventurePlace } from "../../../api/adventurePlace";
import { createAdventureNote, deleteAdventureNote, updateAdventureNote } from "../../../api/adventureNotes";
import MapContainer from "../components/mapContainer";
import CreatePlaceDialog from '../components/createPlaceDialog';
import EditNoteDialog from '../components/editNoteDialog';
import ChangePlaceImageDialog from "../components/changePlaceImageDialog";
import ConfirmationDialog from "../../../modals/confirmationDialog";
import { Grid, Header } from "semantic-ui-react";
import { ADVENTURE_PLACE_PAGE_LINK } from "../../../router/links";

const AdventurePlaceMapTab = ({
    place,
    chapters,
    persons,
    notes,
    childPlaces,
    reloadData,
    reloadNotes,
    reloadChildPlaces,
    disabled
}) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [editNoteDialog, setEditNoteDialog] = useState({ isOpen: false });
    const [createPlaceDialog, setCreatePlaceDialog] = useState({ isOpen: false });
    const [changeImageDialog, setChangeImageDialog] = useState({ isOpen: false });
    const [confirmationDialog, setConfirmationDialog] = useState({ isOpen: false });
    const [newEntryErrors, setNewEntryErrors] = useState({});
    const [changeEntryErrors, setChangeEntryErrors] = useState({});
    const [editNoteErrors, setEditNoteErrors] = useState({});

    const contextMenuItems = [
        {
            icon: 'map marker',
            name: 'Новая локация',
            onClick: (x, y) => {
                setNewEntryErrors({});
                setCreatePlaceDialog({
                    isOpen: true,
                    x, 
                    y
                });
            }
        },
        {
            icon: 'sticky note outline',
            name: 'Новая заметка',
            onClick: (x, y) => setEditNoteDialog({
                isOpen: true,
                form: {
                    adventure: {
                        id: place.adventureId,
                        name: place.adventureName
                    },
                    place: {
                        id: place.id,
                        name: place.name
                    },
                    placeX: x,
                    placeY: y
                }
            })
        },
        {
            icon: 'sync',
            name: 'Заменить изображение',
            onClick: () => {
                setChangeEntryErrors({});
                setChangeImageDialog({
                    isOpen: true,
                    title: `Замена изображения для ${place.name}`
                });
            }
        }
    ];

    const closeConfirmation = () => setConfirmationDialog({ isOpen: false });
    
    const closeChangeImageDialog = () => setChangeImageDialog({ isOpen: false });

    const onChangeImageSave = (data) => {
        setIsLoading(true);
        setChangeEntryErrors({});
        replaceAdventurePlaceFile(place.id, data)
            .then(() => {
                closeChangeImageDialog();
                reloadData();
            })
            .catch((result) => {
                setIsLoading(false);
                setChangeEntryErrors(result && result.errors);
            });
    };
    
    const closeEditNoteDialog = () => setEditNoteDialog({ isOpen: false });

    const onEditNoteSave = (data) => {
        setIsLoading(true);
        setEditNoteErrors({});
        (data.id ? updateAdventureNote(data.id, data) : createAdventureNote(data))
            .then(() => {
                closeEditNoteDialog();
                reloadNotes();
                setIsLoading(false);
            })
            .catch((result) => {
                setIsLoading(false);
                setEditNoteErrors(result && result.errors);
            });
    };
    
    const closeCreatePlaceDialog = () => setCreatePlaceDialog({ isOpen: false });

    const onCreatePlaceSave = (data) => {
        setIsLoading(true);
        setNewEntryErrors({});
        createAdventurePlace(place.adventureId, data)
            .then(() => {
                closeCreatePlaceDialog();
                reloadChildPlaces();
                setIsLoading(false);
            })
            .catch((result) => {
                setIsLoading(false);
                setNewEntryErrors(result && result.errors);
            });
    };

    const mapPoints = childPlaces.map((childPlace) => ({
        icon: 'map marker',
        title: childPlace.name,
        x: childPlace.parentPlaceX,
        y: childPlace.parentPlaceY,
        contextItems: [
            {
                content: <Header as='h4'>{childPlace.name}</Header>,
                onClick: () => navigate(ADVENTURE_PLACE_PAGE_LINK.replace(':id', childPlace.id))
            },
            {
                icon: 'trash alternate',
                name: 'Удалить локацию',
                onClick: () => setConfirmationDialog({
                    isOpen: true,
                    title: 'Удаление локации',
                    message: `Вы действительно хотите удалить локацию '${childPlace.name}'?`,
                    onSave: () => {
                        closeConfirmation();
                        setIsLoading(true);
                        deleteAdventurePlace(childPlace.id)
                            .then(() => {
                                reloadChildPlaces();
                                setIsLoading(false);
                            })
                            .catch(() => setIsLoading(false));
                    }
                })
            }
        ],
        onDoubleClick: () => navigate(ADVENTURE_PLACE_PAGE_LINK.replace(':id', childPlace.id)),
        onMove: (x, y) => updateAdventurePlace(childPlace.id, {
            ...childPlace,
            parentPlaceX: x,
            parentPlaceY: y
        }).then(() => reloadChildPlaces())
    })).concat(notes.map((note) => ({
        icon: note.icon,
        title: note.name,
        x: note.placeX,
        y: note.placeY,
        contextItems: [
            {
                content: <Header as='h4'>{note.name}</Header>,
                icon: 'edit',
                name: 'Править заметку',
                onClick: () => {
                    setEditNoteErrors({});
                    setEditNoteDialog({
                        isOpen: true,
                        form: note
                    });
                }
            },
            {
                icon: 'trash alternate',
                name: 'Удалить заметку',
                onClick: () => setConfirmationDialog({
                    isOpen: true,
                    title: 'Удаление заметки',
                    message: `Вы действительно хотите удалить заметку?`,
                    onSave: () => {
                        closeConfirmation();
                        setIsLoading(true);
                        deleteAdventureNote(note.id)
                            .then(() => {
                                reloadNotes();
                                setIsLoading(false);
                            })
                            .catch(() => setIsLoading(false));
                    }
                })
            }
        ],
        onDoubleClick: () => {
            setEditNoteErrors({});
            setEditNoteDialog({
                isOpen: true,
                form: note
            });
        },
        onMove: (x, y) => onEditNoteSave({
            ...note,
            placeX: x,
            placeY: y
        })
    })));

    return (
        <>
            {place.id && <Grid.Row>
                <Grid.Column>
                    <MapContainer 
                        id={place.id}
                        disabled={disabled || isLoading}
                        key={"map-place-" + place.id}
                        mapUrl={'/api/v1/files/adventurePlace/' + place.fileId}
                        points={mapPoints}
                        contextMenuItems={contextMenuItems}
                    />
                </Grid.Column>
            </Grid.Row>}

            <ChangePlaceImageDialog 
                isOpen={changeImageDialog.isOpen}
                disabled={disabled || isLoading}
                title={changeImageDialog.title}
                onSave={onChangeImageSave}
                onCancel={closeChangeImageDialog}
                errors={changeEntryErrors}
            />

            <CreatePlaceDialog 
                isOpen={createPlaceDialog.isOpen}
                disabled={disabled || isLoading}
                title='Создание новой локации'
                chapters={chapters}
                fixedChapterId={place.chapterId}
                parentId={place.id}
                parentX={createPlaceDialog.x}
                parentY={createPlaceDialog.y}
                onSave={onCreatePlaceSave}
                onCancel={closeCreatePlaceDialog}
                errors={newEntryErrors}
            />

            <EditNoteDialog 
                isOpen={editNoteDialog.isOpen}
                disabled={disabled || isLoading}
                chapters={chapters}
                persons={persons}
                entry={editNoteDialog.form}
                onSave={onEditNoteSave}
                onCancel={closeEditNoteDialog}
                errors={editNoteErrors}
            />
            
            <ConfirmationDialog
                isOpen={confirmationDialog.isOpen}
                title={confirmationDialog.title}
                message={confirmationDialog.message}
                onCancel={closeConfirmation}
                onSave={confirmationDialog.onSave}
            />
        </>
    );
};

export default AdventurePlaceMapTab;