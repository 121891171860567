import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Container, Dropdown, DropdownItem, DropdownMenu, Grid, Header, Icon, Tab } from "semantic-ui-react";
import { ADVENTURE_PAGE_LINK, ADVENTURE_PERSON_INFO_HASH, ADVENTURE_PERSON_NOTES_HASH, ADVENTURE_PERSONS_HASH } from "../../router/links";
import AdventurePersonInfoTab from "./tabs/adventurePersonInfoTab";
import AdventurePersonNotesTab from "./tabs/adventurePersonNotesTab";
import { loadAdventurePersonData, updateAdventurePersonField } from "../../api/adventurePersons";
import { personLinks } from "../../api/externalLinks";
import AutoSaveInput from "./components/autoSaveInput";

const AdventurePersonPage = () => {
    const { id } = useParams();
    const { hash } = useLocation();
    const navigate = useNavigate();
    
    const [form, setForm] = useState({});
    const [name, setName] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const getData = () => {
        setIsLoading(true);
        loadAdventurePersonData(id)
            .then(result => {
                setIsLoading(false);

                setForm(result);
                setName(result.name || '');
                
                document.title = `Overdice - ${result.adventureName || 'приключения'} - ${result.name || 'персонажи'}`;
            })
            .catch(() => setIsLoading(false));
    }
    useEffect(getData, [id]);

    const saveField = (fieldName, value) => {
        setIsLoading(true);
        updateAdventurePersonField(id, fieldName, value)
            .then(() => setIsLoading(false))
            .catch(() => setIsLoading(false));
    };

    const panes = [
        {
            tag: ADVENTURE_PERSON_INFO_HASH,
            render: () => (
                <AdventurePersonInfoTab
                    key={'adventure-person-info-' + (form.id || 'none')}
                    disabled={isLoading}
                    person={form} 
                    onSaveField={saveField}
                />
            )
        },
        {
            tag: ADVENTURE_PERSON_NOTES_HASH,
            render: () => (
                <AdventurePersonNotesTab
                    key={'adventure-person-notes-' + (form.id || 'none')}
                    disabled={isLoading}
                    adventureId={form.adventureId} 
                    personId={form.id} 
                />
            )
        }
    ];

    const syncActiveTab = () => {
        if (hash) {
            panes.forEach((tab, index) => {
                if (tab.tag === hash) {
                    setActiveTab(index);
                }
            });
        } else {
            setActiveTab(0);
        }
    };
    useEffect(syncActiveTab, [hash]);

    const goBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
         } else {
            navigate(ADVENTURE_PAGE_LINK.replace(':id', form.adventureId) + ADVENTURE_PERSONS_HASH, { replace: true });
         }
    };

    return (
        <Container>
            <Grid divided='vertically'>
                <Grid.Row className='adventure-header'>
                    <div className='header-main'>
                        <div className='header-back'>
                            <Header size="medium" className="adventure-subpage-header">
                                <Icon name="globe" size="big" />
                            </Header>
                        </div>
                        <div className='header-title'>
                            <Header 
                                size="medium" 
                                className="adventure-subpage-header" 
                                style={{ marginLeft: 10 }}
                                onClick={() => navigate(ADVENTURE_PAGE_LINK.replace(':id', form.adventureId))}
                            >
                                {form.adventureName}
                            </Header>
                        </div>
                    </div>
                </Grid.Row>
                <Grid.Row className='adventure-header adventure-person-header'>
                    <div className='header-main'>
                        <div className='header-back'>
                            <Button circular basic icon='arrow left' onClick={goBack} />
                        </div>
                        <div className='header-title'>
                            <AutoSaveInput 
                                transparent 
                                size='big'
                                icon='user circle'
                                iconPosition='left'
                                className='title-input'
                                disabled={isLoading}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onBlur={() => saveField('name', name)}
                            />
                        </div>
                    </div>
                    <div className='header-links'>
                        <Link 
                            title="Описание"
                            to={ADVENTURE_PERSON_INFO_HASH} 
                            className={'ui basic circular icon button' + (panes[activeTab].tag === ADVENTURE_PERSON_INFO_HASH ? ' black' : '')}
                        >
                            <Icon name="info"/>
                        </Link>
                        <Link 
                            title="Заметки"
                            to={ADVENTURE_PERSON_NOTES_HASH} 
                            className={'ui basic circular icon button' + (panes[activeTab].tag === ADVENTURE_PERSON_NOTES_HASH ? ' black' : '')}
                        >
                            <Icon name="sticky note outline"/>
                        </Link>
                        <Dropdown 
                            title="Полезные ссылки"
                            icon="external share" 
                            className="ui basic circular icon button"
                            simple
                        >
                            <DropdownMenu>
                                {personLinks.map((link, index) => (
                                    <DropdownItem
                                        key={`external-link-${index}`}
                                    >
                                        <Link to={link.url} target="__blank">
                                            {link.name}
                                        </Link>
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </Grid.Row>
            </Grid>
            <Tab menu={{ as: () => <></> }} panes={panes} activeIndex={activeTab} />
        </Container>
    );
};

export default AdventurePersonPage;